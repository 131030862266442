<template>
  <div>
    <h1 class="title is-5 has-text-centered">Variable Description</h1>
    <p class="content" v-if="variable !== null" v-external-links v-html="variable.description"></p>
    <app-loading v-else></app-loading>
  </div>
</template>

<script>

  import { mapState } from "pinia";
  import { useMetadataStore } from "App/stores/metadata";

  import AppLoading from "App/components/AppLoading";

  export default {
    computed: {
      ...mapState(useMetadataStore, [
        "variable"
      ])
    },

    components: {
      AppLoading
    }
  }

</script>