import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAngleDown,
  faCheckSquare,
  faCircle,
  faClipboard,
  faFile,
  faChevronLeft,
  faChevronRight,
  faExclamationTriangle,
  faExpand,
  faMinusSquare,
  faPencilAlt,
  faSearch,
  faSpinner,
  faSquare,
  faTimes,
  faToggleOn
} from '@fortawesome/free-solid-svg-icons';
import AppIcon from 'App/components/AppIcon';

export function configureFontAwesome(app) {
  app.component('font-awesome-icon', FontAwesomeIcon);
  app.component('app-icon', AppIcon);

  library.add(
    faAngleDown,
    faCheckSquare,
    faCircle,
    faClipboard,
    faFile,
    faChevronLeft,
    faChevronRight,
    faExclamationTriangle,
    faExpand,
    faMinusSquare,
    faPencilAlt,
    faSearch,
    faSpinner,
    faSquare,
    faTimes,
    faToggleOn
  );
}
