<template>
  <div :class="{ unavailable: !isAvailable, checked: isSelected }" class="sample list-item">
    <app-checkbox class="list-checkbox" :class="{hide: (!canSelect && !isSelected)}" :value="isSelected" @toggle="sampleClick"></app-checkbox>
    <span class="list-label">
      <app-tabular-text :value="sample.label"></app-tabular-text>
    </span>
    <app-metadata-link v-if="sample.note" @click.native.stop type="sample" :id="sample.id" class="metadata-link alert-icon">
      <app-icon icon="exclamation"></app-icon>
    </app-metadata-link>
    <app-metadata-link @click.native.stop type="sample" :id="sample.id" class="metadata-link">
      <app-icon icon="file"></app-icon>
    </app-metadata-link>
  </div>
</template>

<script>

  import { mapActions, mapState } from 'pinia';
  import { useSelectionStore } from "App/stores/selection";
  import AppCheckbox from 'App/components/AppCheckbox';
  import AppMetadataLink from 'App/components/AppMetadataLink';
  import AppTabularText from "App/components/AppTabularText";

  export default {
    props: {
      sample: {
        type: Object,
        required: true
      }
    },
    computed: {
      ...mapState(useSelectionStore, ['canSelectSample', 'selectedSamples']),

      canSelect() {
        return this.canSelectSample;
      },
      isSelected() {
        return this.isSampleSelected(this.sample);
      },
      isAvailable() {
        return this.isSampleAvailable(this.sample);
      }
    },
    methods: {
      ...mapActions(useSelectionStore, [
        'isSampleAvailable',
        'isSampleSelected'
      ]),

      sampleClick() {
        this.$emit("sampleClick", this.sample);
      }
    },

    components: {
      AppCheckbox,
      AppMetadataLink,
      AppTabularText
    }
  }

</script>

<style lang="scss" scoped>
  @import "~App/styles/variables";

  .checkbox.hide {
    visibility: hidden;
  }
</style>
