<template>
  <search-variables :variable-item-component="variableComponent" :selected-variables="selectedVariables" @variableClick="variableClick"></search-variables>
</template>

<script>

  import { markRaw } from "vue";
  import { mapActions, mapState } from "pinia";
  import { useApplicationConfigStore } from "App/stores/applicationConfig";
  import { useSelectionStore } from "App/stores/selection";
  import SearchVariables from "App/components/SearchVariables";
  import SelectVariablesVariableItem from 'App/components/SelectVariablesVariableItem';

  export default {
    data() {
      return {
        variableComponent: markRaw(SelectVariablesVariableItem)
      };
    },

    computed: {
      ...mapState(useSelectionStore, [
        "canSelectVariable",
        "isVariableSelected",
        "selectedVariables"
      ])
    },

    methods: {
      ...mapActions(useApplicationConfigStore, [
        "addAlert"
      ]),
      ...mapActions(useSelectionStore, [
        "selectVariable",
        "removeVariable"
      ]),

      variableClick(variable) {
        if (!this.isVariableSelected(variable)) {
          if (this.canSelectVariable) {
            this.selectVariable(variable);
          } else {
            this.addAlert({message: 'You may only select 2 variables'});
          }
        } else {
          this.removeVariable(variable);
        }
      }
    },

    components: {
      SearchVariables
    }
  }

</script>

<style lang="scss" scoped>
</style>