<template>
  <div class="tabulator container">
    <div class="header has-text-centered">
      <h4 class="title is-6 has-text-white">IPUMS Abacus</h4>
    </div>
    <div class="view">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>

  export default {

  }

</script>

<style lang="scss" scoped>
@import "~App/styles/variables";

.tabulator {
  background-color: white;

  display: flex;
  flex-direction: column;
  min-height: 90vh;
  padding-bottom: 2em;

  .header {
    background-color: $black-ter;
    padding: 0.4em;
  }

  .view {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

}
</style>
