import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useMediaQueryStore = defineStore('mediaQuery', () => {
  // Hard coded values taken directly from Bulma css
  const mobileBp = 768;
  const desktopBp = 1024;
  const widscreenBp = 1216;
  const fullHdBp = 1408;

  const mediaQueries = {
    mobile: `screen and (max-width: ${mobileBp}px)`,
    tablet: `screen and (min-width: ${mobileBp + 1}px)`,
    tabletOnly: `screen and (min-width: ${mobileBp + 1}px) and (max-width: ${desktopBp - 1}px)`,
    touch: `screen and (max-width: ${desktopBp - 1}px)`,
    desktop: `screen and (min-width: ${desktopBp}px)`,
    desktopOnly: `screen and (min-width: ${desktopBp}px) and (max-width: ${widscreenBp - 1}px)`,
    widescreen: `screen and (min-width: ${widscreenBp}px)`,
    widescreenOnly: `screen and (min-width: ${widscreenBp}px) and (max-width: ${fullHdBp - 1}px)`,
    fullhd: `screen and (min-width: ${fullHdBp}px)`
  };

  const store = {};

  for (let device in mediaQueries) {
    const query = window.matchMedia(mediaQueries[device]);
    store[device] = ref(query.matches);

    query.onchange = (q) => {
      store[device].value = q.matches;
    };
  }

  return store;
});
