<template>
  <div :class="{ checked: isSelected }" class="state list-item" @click.prevent="toggleSelect">
    <app-checkbox @toggle="toggleSelect" class="list-checkbox" :value="isSelected"></app-checkbox>
    <span class="list-label">
      {{place.name}}
    </span>
  </div>
</template>

<script>

  import AppCheckbox from "App/components/AppCheckbox";
  import { mapActions, mapState } from "pinia";
  import { useSelectionStore } from "../stores/selection";

  export default{
    props: {
      place: {
        required: true,
        type: Object
      }
    },
    computed: {
      ...mapState(useSelectionStore, [
          'selectedPlace'
      ]),
      isSelected(){
        if (this.selectedPlace) {
          return this.place.value == this.selectedPlace.value;
        } else {
          return false;
        }
      }
    },
    methods: {
      ...mapActions(useSelectionStore, [
          'selectPlace',
          'removePlace'
      ]),

      toggleSelect(){
        if (!this.isSelected) {
          this.selectPlace(this.place);
        } else if (this.isSelected) {
          this.removePlace();
        }
      }
    },

    components: {
      AppCheckbox
    }
  }
</script>

<style lang="scss" scoped>
  @import "~App/styles/variables";

</style>