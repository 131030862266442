import { Group } from '@tweenjs/tween.js'

let group = null;

export function useGlobalTweenGroup() {
  if (group === null) {
    group = new Group();
  }
  return group;
}
