<template>
  <div id="notFound" class="section has-text-white">
    <div class="container">
      <h1 class="title has-text-white">
        Error: 404
      </h1>
      <p>
        Page Not Found: {{ errorLocation }}
      </p>

      <router-link :to="{ name: 'tabulator' }" class="button is-primary">Return To Tabulator</router-link>

    </div>
  </div>
</template>

<script>
export default {
  computed: {
    errorLocation() {
      return this.$route.fullPath;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~App/styles/variables";

.container {
  color: white;
  text-align: center;
}

</style>
