<template>
  <div>
    <h1 class="has-text-centered title is-5">Variable Codes</h1>

    <div v-if="!localLoading">
      <div v-if="showManualText">
        <div v-external-links v-html="variableCodes.manual_text"></div>
      </div>
      <div v-else-if="variableCategories.length > 0">

        <div class="field" v-if="hasGeneralCategories">
          <app-toggle-switch v-model="showDetailedSwitchState">
            Show Detailed Codes
          </app-toggle-switch>
          <p class="help is-italic" v-if="showDetailed">
            Not yet supported in IPUMS Abacus
          </p>
        </div>

        <table class="table is-narrow">
          <tr v-for="cat in variableCategories" :key="cat.id">
            <td>
              <app-tabular-text :value="showDetailed ? cat.formatted_code : cat.general_code"></app-tabular-text>
            </td>
            <td>
              <span class="indent-spacer" v-for="x in ((showDetailed ? cat.indent : cat.general_indent) || 0)"></span>
              <span v-if="showDetailed">{{ cat.label }}</span>
              <span v-else>{{ cat.general_label }}</span>
            </td>
          </tr>
        </table>
      </div>
      <div v-else>
        No Code Data
      </div>
    </div>
    <app-loading v-else></app-loading>
  </div>
</template>

<script>

  import { mapActions, mapState } from "pinia";
  import { useMetadataStore } from "App/stores/metadata";

  import AppLoading from "App/components/AppLoading";
  import AppTabularText from "App/components/AppTabularText";
  import AppToggleSwitch from "App/components/AppToggleSwitch";

  export default {
    props: {
      variableId: {
        required: true,
        type: Number
      }
    },

    data() {
      return {
        showDetailedSwitchState: false
      };
    },

    computed: {
      ...mapState(useMetadataStore, [
        "variable",
        "variableCodes"
      ]),

      showDetailed() {
        if (!this.hasGeneralCategories) {
          return true;
        } else {
          return this.showDetailedSwitchState;
        }
      },

      hasGeneralCategories() {
        return this.variableCodes !== null && this.variableCodes.is_general_detailed;
      },

      showManualText() {
        return this.variableCodes && this.variableCodes.manual_text !== null;
      },

      variableCategories() {
        if (this.variableCodes === null) {
          return [];
        } else if (this.showDetailed || !this.hasGeneralCategories) {
          return this.variableCodes.categories;
        } else {
          return this.variableCodes.categories.filter(c => c.is_general)
        }
      }
    },

    methods: {
      ...mapActions(useMetadataStore, [
        "getVariableCodes"
      ])
    },

    watch: {
      variableId: {
        handler: function() {
          this.loadResource(
            this.getVariableCodes(this.variableId)
          );
        },
        immediate: true
      }
    },

    components: {
      AppLoading,
      AppTabularText,
      AppToggleSwitch
    }
  }

</script>

<style lang="scss" scoped>
  @import "~App/styles/variables";

  .table {
    background-color: $grey;
  }

  .indent-spacer {
    display: inline-flex;
    width: 1em;
  }

</style>