<template>
  <div class="drawer">
    <div class="contentWrapper" :style="wrapperStyle" ref="wrapper">
      <slot></slot>
    </div>
    <div class="handleWrapper" @mousedown.left.prevent="mousedown" @touchstart="touchstart">
      <app-svg class="handle" icon="drag"></app-svg>
    </div>
  </div>
</template>

<script>

  import TWEEN from '@tweenjs/tween.js';
  import AppSvg from "App/components/AppSvg";
  import { useGlobalTweenGroup } from "App/lib/useGlobalTweenGroup";

  export default {
    data() {
      return {
        open: true,
        height: 0,
        heightAnimation: null,

        maxHeight: null,
        dragging: false,
        dragOriginY: null
      }
    },

    mounted() {

    },

    computed: {
      wrapperStyle() {
        return {
          height: (this.open === true && this.dragging === false && this.heightAnimation === null) ? 'auto' : this.height.toString() + "px"
        };
      }
    },

    methods: {
      registerMouseListeners() {
        document.body.addEventListener('mousemove', this.mousemove);
        document.body.addEventListener('mouseleave', this.mouseleave);
        document.body.addEventListener('mouseup', this.mouseup);
      },

      disableMouseListeners() {
        document.body.removeEventListener('mousemove', this.mousemove);
        document.body.removeEventListener('mouseleave', this.mouseleave);
        document.body.removeEventListener('mouseup', this.mouseup);
      },

      registerTouchListeners() {
        document.body.addEventListener('touchmove', this.touchmove);
        document.body.addEventListener('touchend', this.touchend);
      },

      disableTouchListeners() {
        document.body.removeEventListener('touchmove', this.touchmove);
        document.body.removeEventListener('touchend', this.touchend);
      },

      mousedown(evt) {
        this.startDrag(evt.clientY, false);
      },

      mouseup(evt) {
        this.endDrag(evt.clientY, false);
      },

      mousemove(evt) {
        if (this.dragging) {
          this.drag(evt.clientY, false);
        }
      },

      mouseleave(evt) {
        this.endDrag(null, false);
      },

      touchstart(evt) {
        evt.preventDefault();
        let touch = evt.touches[0];
        if (touch) {
          this.startDrag(touch.clientY, true);
        }
      },

      touchmove(evt) {
        if (this.dragging) {
          let touch = evt.touches[0];
          if (touch) {
            this.drag(touch.clientY, true);
          }
        }
      },

      touchend(evt) {
        let touch = evt.changedTouches[0];
        if (touch) {
          this.endDrag(touch.clientY, true);
        } else {
          this.endDrag(null, true);
        }
      },

      drag(yCoord, isTouch) {
        if (this.dragging) {
          const deltaY = this.dragOriginY - yCoord;

          let newHeight = 0;

          if (this.open === true) {
            newHeight = this.maxHeight - deltaY;
          } else if (deltaY < 0) {
            newHeight = Math.abs(deltaY);
          } else {
            newHeight = 0;
          }

          if (newHeight <= this.maxHeight && newHeight >= 0) {
            this.changeHeight(newHeight, true);
          } else {
            this.changeHeight(newHeight < 0 ? 0 : this.maxHeight, true);
          }
        }
      },

      startDrag(yCoord, isTouch) {
        if (this.open) {
          this.maxHeight = this.$refs.wrapper.scrollHeight;
        }

        this.dragging = true;
        this.dragOriginY = yCoord;
        this.height = this.open ? this.maxHeight : 0;

        if (isTouch) {
          this.registerTouchListeners();
        } else {
          this.registerMouseListeners();
        }
      },

      endDrag(yCoord, isTouch) {
        if (isTouch) {
          this.disableTouchListeners();
        } else {
          this.disableMouseListeners();
        }

        const minSwipe = 50;
        let delta = 0;

        if (yCoord !== null) {
          delta = this.dragOriginY - yCoord;
        }

        if (this.open === true && (delta > minSwipe || Math.abs(delta) < 4)) {
          // drawer is open and user has clicked without moving the mouse or moved the drawer minSwipe pixels towards being closed
          this.open = false;
          this.changeHeight(0);
        } else if (this.open !== true && (delta < -minSwipe || Math.abs(delta) < 4)) {
          // drawer is closed and user has clicked without moving the mouse or moved the drawer minSwipe pixels towards being opened
          this.open = true;
          this.changeHeight(this.maxHeight);
        } else {
          // User dragged the drawer, but did not drag it far enough to trigger a change; animate the height back into place.
          this.changeHeight(this.open === true ? this.maxHeight : 0);
        }

        this.dragging = false;
        this.dragOriginY = null;
      },

      changeHeight(newValue, runFast) {
        if (this.heightAnimation !== null) {
          this.heightAnimation.stop();
          this.heightAnimation = null;
        }

        if (runFast) {
          this.height = newValue;
        } else {
          this.heightAnimation = new TWEEN.Tween(this, useGlobalTweenGroup())
            .to({height: newValue}, 250)
            .easing(TWEEN.Easing.Quadratic.In)
            .onComplete(() => this.heightAnimation = null)
            .start();
        }
      }
    },

    components: {
      AppSvg
    }
  }

</script>

<style lang="scss" scoped>

  @import "~App/styles/variables";

  .contentWrapper {
    overflow-y: hidden;
  }

  .drawer {
    border-bottom: 3px solid $cyan;
    padding-bottom: 0.125rem;
  }

  .handleWrapper {
    padding-top: 5px;
    padding-bottom: 3px;
  }

  .handle {
    color: $cyan;
    width: 35px;
    display: block;
    margin: 0 auto;
  }

</style>