<template>

  <div>
    <app-popup
      :open="isSelecting"
      @dismiss="closeSelection"
      :title="title">

      <div class="option" :class="{selected: isSelected(opt)}" v-for="opt in options" :key="optionValue(opt)" @click="select(opt)">
        {{ optionLabel(opt) }}
      </div>

    </app-popup>

    <button class="button is-primary" :class="buttonClass" :style="buttonStyle" @click="openSelection">
      <span>{{ optionLabel(value) || unselectedText }}</span>&nbsp;
      <app-icon icon="angleDown" size="sm"></app-icon>
    </button>

  </div>

</template>

<script>

  import AppPopup from "App/components/AppPopup";

  export default {
    props: {
      options: {
        required: true,
        type: Array
      },
      value: {
        default: null
      },
      title: {
        type: String,
        default: 'Select Value'
      },
      unselectedText: {
        default: "-- Select --"
      },
      valueAttribute: String,
      labelAttribute: String,
      buttonClass: {
        type: [String, Array, Object],
        default: "button is-primary"
      },
      buttonStyle: Object
    },

    data() {
      return {
        isSelecting: false
      }
    },

    computed: {

    },

    methods: {
      openSelection() {
        this.isSelecting = true;
      },

      closeSelection() {
        this.isSelecting = false;
      },

      isSelected(opt) {
        return (opt !== null && this.value !== null && this.optionValue(opt) === this.optionValue(this.value));
      },

      select(opt) {
        this.$emit("optionSelected", opt);
        this.closeSelection();
      },

      optionValue(opt) {
        if (opt === null) {
          return null;
        }

        if (this.valueAttribute) {
          return opt[this.valueAttribute];
        } else {
          return opt.toString();
        }
      },

      optionLabel(opt) {
        if (opt === null) {
          return null;
        }

        if (this.labelAttribute) {
          return opt[this.labelAttribute];
        } else {
          return opt.toString();
        }
      },
    },

    components: {
      AppPopup
    }
  }

</script>

<style lang="scss" scoped>

  @import "~App/styles/variables";

  div.option {

    background-color: $secondary;
    color: $white;
    margin: 0.75rem;
    padding: 0.5rem;

    &.selected {
      background-color: $primary;
    }
  }

</style>