<template>
  <section class="section">
    <div class="container has-text-centered has-text-white">
      <h1>Restoring Session...</h1>

      <app-loading v-if="loading" size="lg">
        <br/><span>Loading...</span>
      </app-loading>
    </div>
  </section>
</template>

<script>

  import { mapActions } from 'pinia';
  import { useApplicationConfigStore } from "../stores/applicationConfig";
  import { useSelectionStore } from "../stores/selection";
  import * as Errors from 'App/lib/Errors';

  import AppLoading from 'App/components/AppLoading';

  export default {

    data() {
      return {
        loading: false
      }
    },

    mounted() {
      if (!this.sessionData) {
        this.$router.push({name: 'tabulator'});
      } else {
        this.loading = true;
        this.restoreSession(this.sessionData)
          .then(() => this.$router.push({name: 'tabulator'}))
          .catch(Errors.onlyFor(Errors.SessionSerializationError, (er) => {
            this.addAlert({message: 'Unable to restore session'});
            this.$router.push({name: 'tabulator'});
          }))
          .then(() => this.loading = false);
      }
    },

    computed: {
      sessionData() {
        const query = this.$route.query;
        return (query && query.session) || null;
      }
    },

    methods: {
      ...mapActions(useApplicationConfigStore, [
        'addAlert'
      ]),
      ...mapActions(useSelectionStore, [
        'restoreSession'
      ])
    },

    components: {
      AppLoading
    }
  }
</script>

<style lang="scss" scoped>
  @import "~App/styles/variables";



</style>
