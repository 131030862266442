<template>
  <div>
    <h1 class="has-text-centered title is-5">Editing Procedure</h1>

    <div class="content" v-if="!localLoading && variableEditing !== null" v-external-links v-html="variableEditing.text"></div>

    <div v-if="variableEditing !== null && (variableEditing.text === null || variableEditing.text.length === 0)">
      There is no editing procedure available for this variable.
    </div>

    <app-loading v-if="localLoading"></app-loading>
  </div>
</template>

<script>

  import { mapActions, mapState } from "pinia";
  import { useMetadataStore } from "App/stores/metadata";

  import AppLoading from "App/components/AppLoading";

  export default {
    props: {
      variableId: {
        required: true,
        type: Number
      }
    },

    computed: {
      ...mapState(useMetadataStore, [
        "variable",
        "variableEditing"
      ])
    },

    methods: {
      ...mapActions(useMetadataStore, [
        "getVariableEditing"
      ])
    },

    watch: {
      variableId: {
        handler: function() {
          this.loadResource(
            this.getVariableEditing(this.variableId)
          );
        },
        immediate: true
      }
    },

    components: {
      AppLoading
    }
  }

</script>