<template>
  <div class="variable-search">

    <app-sticky-container>
      <app-return-header :to="{ name: 'tabulator' }">
        {{titleText}}
      </app-return-header>

      <div class="selection">
        <fade-transition-group>
          <div v-for="variable in selectedVariables" :key="variable.id">
            <component :is="variableItemComponent" class="selectedVariable" :variable="variable" @variableClick="variableClick"></component>
          </div>
        </fade-transition-group>
      </div>

    </app-sticky-container>

    <div class="search">
      <fieldset>
        <div class="control has-icons-left">
          <app-autocomplete
              ref="autocomplete"
              v-model="searchText"
              :minLength="2"
              valueAttribute="mnemonic"
              labelAttribute="formatted_label"
              placeholder="SEARCH"
              @optionSelected="searchItemSelected"
              :onGetOptions="updateSearchItems"
          >
          </app-autocomplete>

          <span class="is-left">
            <app-icon size="sm" icon="search"></app-icon>
          </span>
        </div>
      </fieldset>
    </div>

    <div v-if="availableToggle" class="options">
      <app-toggle-switch :model-value="showUnavailable" @toggle="toggleUnavailable"></app-toggle-switch>
      Display {{ unavailableCount }} unavailable variables
    </div>

    <div class="row list">
      <app-loading v-if="localLoading"></app-loading>
      <template v-else>
        <search-variables-group-item
            v-for="vg in variableGroups"
            :key="vg.id"
            :variableGroup="vg"
            :selectedVariables="selectedVariables"
            :showUnavailable="showUnavailable"
            :openGroupId="openGroupId"
            :variable-item-component="variableItemComponent"
            @headerClick="openGroup"
            @variableClick="variableClick">
        </search-variables-group-item>
      </template>
    </div>
  </div>
</template>

<script>

  import AppAutocomplete from 'App/components/AppAutocomplete';
  import AppLoading from 'App/components/AppLoading';
  import AppReturnHeader from "App/components/AppReturnHeader";
  import AppToggleSwitch from 'App/components/AppToggleSwitch';
  import AppStickyContainer from 'App/components/AppStickyContainer';
  import CollapseTransition from 'App/components/CollapseTransition';
  import FadeTransitionGroup from 'App/components/FadeTransitionGroup';
  import SearchVariablesGroupItem from 'App/components/SearchVariablesGroupItem';
  import { mapActions, mapState } from "pinia";
  import { useSelectionStore } from "App/stores/selection";
  import { useSearchVariablesStore } from "App/stores/searchVariables";
  import api from 'App/lib/Api';

  export default {
    props: {
      titleText: {
        required: false,
        type: String,
        default: "Select Variable(s)"
      },

      availableToggle: {
        required: false,
        type: Boolean,
        default: true
      },

      variableItemComponent: {
        required: true,
        type: Object
      },

      selectedVariables: {
        required: true,
        type: Array
      }
    },

    data() {
      return {
        showUnavailable: false,
        searchText: ''
      }
    },
    mounted() {
      this.loadResource(this.getVariableGroups());
    },

    computed: {
      ...mapState(useSelectionStore, ['selectedSamples']),
      ...mapState(useSearchVariablesStore, ['variableGroups', 'hasVariableGroups']),

      openGroupId() {
        return parseInt(this.$route.query.openGroupId) || null;
      },

      unavailableCount() {
        let i = 0;

        for (let x = 0; x < this.variableGroups.length; x++) {
          let g = this.variableGroups[x];
          i += (g.variableCount - g.availableVariableCount);
        }
        return i;
      }
    },

    methods: {
      ...mapActions(useSearchVariablesStore, ['getVariableGroups']),

      toggleUnavailable() {
        this.showUnavailable = !this.showUnavailable;
      },
      openGroup(vg) {
        if (this.openGroupId == vg.id) {
          this.$router.push({name: this.$route.name, params: this.$route.params});
        } else {
          this.$router.push({name: this.$route.name, params: this.$route.params, query: {openGroupId: vg.id}});
        }
      },
      updateSearchItems(text) {
        return api.variableSearch(this.selectedSamples.map(s => s.id), text)
          .then(vars => {
            vars.forEach(v => v.formatted_label = v.label + " : " + v.description);
            return vars;
          });
      },
      variableClick(variable) {
        this.$emit("variableClick", variable);
      },
      searchItemSelected(variable) {
        this.$emit("variableClick", variable);

        this.searchText = "";
      }
    },

    watch: {
      openGroupId: {
        handler: function() {

        },
        immediate: true
      }
    },

    components: {
      AppAutocomplete,
      AppLoading,
      AppStickyContainer,
      AppReturnHeader,
      CollapseTransition,
      FadeTransitionGroup,
      SearchVariablesGroupItem,
      AppToggleSwitch
    }
  }

</script>

<style lang="scss" scoped>
  @import "~App/styles/variables";

  .selectedVariable {
    border-bottom: 2px solid $green-dark;
  }

  .search fieldset {
    border: none;
  }

  .search, .options, .list {
    margin-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
  }

</style>
