<template>
  <span tabindex="0" role="checkbox" :aria-checked="ariaChecked" @click.stop.prevent="handleClick" @keydown="handleKeydown">
    <app-icon size="lg" :icon="icon"></app-icon>
  </span>
</template>

<script>
  export default {
    emits: ['toggle'],
    props: {
      value: {
        validator: value => [true, false, null].indexOf(value) >= 0
      }
    },
    computed: {
      icon() {
        if (this.value === true) {
          return "checkboxChecked";
        } else if (this.value === false) {
          return "checkbox";
        } else {
          return "checkboxMixed";
        }
      },

      ariaChecked() {
        if (this.value === true) {
          return "true";
        } else if (this.value === false) {
          return "false";
        } else {
          return "mixed";
        }
      }
    },
    methods: {
      handleClick() {
        this.$emit('toggle');
      },
      handleKeydown(event) {
        if (event.isComposing || event.keyCode === 229) {
          return;
        }

        switch (event.key) {
          case " ":
          case "Spacebar":
          case "Enter":
            this.$emit('toggle');
            event.preventDefault();
            break;
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "~App/styles/variables";

</style>
