<template>

  <div :class="{ unavailable: !isAvailable, checked: isSelected, unselectable: (!canSelect && !isSelected) }" class="variable list-item">

    <app-checkbox class="list-checkbox" :value="isSelected" @toggle="toggleSelect"></app-checkbox>

    <div class="list-label">
    {{variable.mnemonic}} - {{variable.label}}
    </div>

    <app-metadata-link @click.native.stop type="variable" :id="variable.id" class="metadata-link" :label="variable.mnemonic + ' metadata'">
      <app-icon icon="file"></app-icon>
    </app-metadata-link>

  </div>

</template>

<script>

  import { mapActions, mapState } from 'pinia';
  import { useSelectionStore } from "App/stores/selection";
  import AppCheckbox from "App/components/AppCheckbox";
  import AppMetadataLink from "App/components/AppMetadataLink";

  export default {
    props: {
      variable: {
        type: Object,
        required: true
      }
    },
    computed: {
      ...mapState(useSelectionStore, ['canSelectVariable']),

      isAvailable() {
        return this.isVariableAvailable(this.variable);
      },
      isSelected() {
        return this.isVariableSelected(this.variable);
      },
      canSelect() {
        return this.canSelectVariable;
      },
    },
    methods: {
      ...mapActions(useSelectionStore, ['isVariableAvailable', 'isVariableSelected']),

      toggleSelect() {
        this.$emit("variableClick", this.variable);
      }
    },

    components: {
      AppCheckbox,
      AppMetadataLink
    }
  }

</script>

<style lang="scss" scoped>
  @import "~App/styles/variables";

  .check.hide {
    visibility: hidden;
  }

</style>
