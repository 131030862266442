
const isFullscreenAvailable = document.fullscreenEnabled ||
  document.webkitFullscreenEnabled ||
  document.mozFullScreenEnabled ||
  document.msFullscreenEnabled;

function isFullscreen() {
  return document.fullscreenElement ||
    document.webkitFullscreenElement ||
    document.mozFullScreenElement ||
    document.msFullscreenElement;
}

function enableFullscreen() {
  const element = document.documentElement;

  if(element.requestFullscreen) {
    element.requestFullscreen();
  } else if(element.mozRequestFullScreen) {
    element.mozRequestFullScreen();
  } else if(element.webkitRequestFullscreen) {
    element.webkitRequestFullscreen();
  } else if(element.msRequestFullscreen) {
    element.msRequestFullscreen();
  }
}

function cancelFullscreen() {
  if(document.exitFullscreen) {
    document.exitFullscreen();
  } else if(document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if(document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  }
}

function toggleFullscreen() {
  if (isFullscreenAvailable) {
    if (isFullscreen()) {
      cancelFullscreen();
    } else {
      enableFullscreen();
    }
  }
}

export default {
  isFullscreenAvailable: isFullscreenAvailable,
  isFullScreen: isFullscreen,
  enableFullscreen: enableFullscreen,
  cancelFullscreen: cancelFullscreen,
  toggleFullscreen: toggleFullscreen
}
