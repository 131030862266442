import "App/styles";

import { createApp, h, onBeforeMount, onMounted } from 'vue';
import { createPinia } from 'pinia';
import { configureFontAwesome } from "App/lib/Fontawesome";
import { configureMixins } from "App/lib/VueMixins";
import { configureExternalLinks } from "App/directives/ExternalLinks";
import router from "App/router";
import { useApplicationConfigStore } from "App/stores/applicationConfig";
import App from "App/components/App";
// Vue.use(VueResize);
//
document.addEventListener("DOMContentLoaded", () => {

  const appElement = document.getElementById("app");
  const appDataset = appElement.dataset;

  const app = createApp({
    name: 'RootApp',
    setup() {
      onBeforeMount(() => {
        const config = useApplicationConfigStore();
        config.baseApiUrl = appDataset.url;
        config.baseIpumsUrl = appDataset.ipums_url;
        config.isIOS = !!(navigator && navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform));
      });

      onMounted(() => {
        window.document.body.classList.remove("loading");
      });

      return () => h(App);
    }
  });

  const pinia = createPinia();
  app.use(pinia);
  app.use(router);
  configureMixins(app);
  configureFontAwesome(app);
  configureExternalLinks(app);
  app.mount(appElement);
});
