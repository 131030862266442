<template>

  <transition-group
      name="fade"
      :css="false"
      @before-enter="before"
      @enter="enter"
      @enter-cancelled="cancel"
      @leave="leave"
      @leave-cancelled="cancel"
  >
    <slot></slot>
  </transition-group>

</template>

<script>

  import TWEEN from '@tweenjs/tween.js';
  import { useGlobalTweenGroup } from "App/lib/useGlobalTweenGroup";
  const globalTweenGroup = useGlobalTweenGroup();

  export default {
    data() {
      return {
        currentAnimation: null
      }
    },

    methods: {
      cancel () {
        if (this.currentAnimation) {
          this.currentAnimation.stop();
          this.currentAnimation = null;
        }
      },
      before (targets) {
        targets.removeAttribute('style')
      },
      enter (targets, done) {
        const height = targets.scrollHeight;
        targets.style.opacity = 0;
        targets.style.overflow = 'hidden';

        this.currentAnimation = new TWEEN.Tween({height: 0, opacity: 0}, globalTweenGroup)
          .to({height: height, opacity: 1}, 250)
          .easing(TWEEN.Easing.Quadratic.In)
          .onUpdate(obj => {
            targets.style.height = obj.height + "px";
            targets.style.opacity = obj.opacity;
          })
          .onComplete(() => {
            this.heightAnimation = null;
            targets.removeAttribute('style');
            done();
          })
          .start();
      },
      leave (targets, done) {
        const height = targets.scrollHeight;
        targets.style.overflow = 'hidden';

        this.currentAnimation = new TWEEN.Tween({height: height, opacity: 1}, globalTweenGroup)
          .to({height: 0, opacity: 0}, 250)
          .easing(TWEEN.Easing.Quadratic.In)
          .onUpdate(obj => {
            targets.style.height = obj.height + "px";
            targets.style.opacity = obj.opacity;
          })
          .onComplete(() => {
            this.heightAnimation = null;
            targets.removeAttribute('style');
            done();
          })
          .start();
      }
    }
  }

</script>

<style lang="scss" scoped>

  * {
    will-change: height;
    transform: translateZ(0);
    backface-visibility: hidden;
    perspective: 1000px;
  }

</style>