<template>
  <div class="header title-row has-text-centered">
    <div class="center-container">
      <router-link :to="to" class="back-link" aria-label="Back">
        <app-icon icon="chevronLeft"></app-icon>
      </router-link>

      <div class="header-text">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    props: {
      to: {
        type: [Object, String],
        required: true
      }
    }
  }

</script>

<style lang="scss" scoped>

  .header {
    .center-container {
      display: flex;

      padding-right: 1.5em;
      .back-link {
        width: 1.5em;
      }

      .header-text {
        flex-grow: 1;
        min-width: 0;
        flex-basis: auto;
      }
    }
  }

</style>