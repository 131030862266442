<template>
  <div class="section has-text-white">
    <div class="container">
      <h1 class="title is-3 has-text-danger">
        An Unexpected Error Has Occurred
      </h1>
      <h3 class="subtitle is-5 has-text-white">
        Our developers have been notified.  We apologize for the inconvenience.
      </h3>

      <div class="buttons">
        <a href="#" class="button is-primary" @click="retry">Retry</a>

        <a :href="restoreLink" class="button is-primary">Reload Session</a>

        <a :href="refreshLink" class="button is-primary">Start Over</a>
      </div>

    </div>
  </div>
</template>

<script>

  import { mapActions, mapState } from "pinia";
  import { useApplicationConfigStore } from "../stores/applicationConfig";
  import { useSelectionStore } from "../stores/selection";
  import api from "App/lib/Api";

  export default {
    computed: {
      ...mapState(useApplicationConfigStore, [
          'errorMessage'
      ]),
      ...mapState(useSelectionStore, [
        'serializedSession'
      ]),
      message() {
        return this.errorMessage;
      },

      refreshLink() {
        return api.url("");
      },

      restoreLink() {
        const serializedSession = this.serializedSession;
        const l = window.location;
        return l.protocol + "//" + l.host + `/session?session=${serializedSession}`;
      }
    },

    methods: {
      ...mapActions(useApplicationConfigStore, [
          'clearError'
      ]),

      retry() {
        this.clearError();
      }
    }
  }

</script>

<style lang="scss" scoped>
  @import "~App/styles/variables";

  .container {
    color: white;
    text-align: center;
  }

</style>
