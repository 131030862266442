<template>
  <div>

    <app-sticky-container>
      <div class="header title-row has-text-centered">
        <div class="center-container">
          <a href="#" class="is-pulled-left back-link" @click.prevent="finish" aria-label="Back">
            <app-icon icon="chevronLeft"></app-icon>
          </a>

          Select Variable Codes to Restrict to
        </div>
      </div>
    </app-sticky-container>

    <div v-if="isLoading || subpopulation === null">
      <app-loading></app-loading>
    </div>

    <div v-else>
      <div class="has-text-centered variable-label">
        {{ subpopulation.variable.mnemonic }} - {{ subpopulation.variable.label }}
      </div>

      <div class="list">
        <div class="list-item checked" @click="selectAllClick">
          <span class="list-label">
            <app-checkbox :value="selectAllChecked" @toggle="selectAllClick"></app-checkbox>
            Select All
          </span>
        </div>
        <category-item
            v-for="cat in subpopulation.categories"
            :key="cat.id"
            :category="cat"
            :is-selected="isCategorySelected(cat)"
            @toggleSelection="toggleSelection">
        </category-item>
      </div>
    </div>

  </div>
</template>

<script>

  import { mapActions, mapState } from "pinia";
  import { useSelectionStore } from "App/stores/selection";
  import api from "App/lib/Api";
  import Subpopulation from "App/lib/Subpopulation";

  import AppCheckbox from "App/components/AppCheckbox";
  import AppLoading from "App/components/AppLoading";
  import AppStickyContainer from "App/components/AppStickyContainer";
  import CategoryItem from "App/components/CategoryItem";

  export default {
    data() {
      return {
        subpopulation: null
      };
    },

    computed: {
      ...mapState(useSelectionStore, [
        "selectedSubpopulations"
      ]),

      variableId() {
        return parseInt(this.$route.params.variableId);
      },

      allSelected() {
        return this.subpopulation.categories.length === this.subpopulation.selectedCategories.length;
      },

      noneSelected() {
        return this.subpopulation.selectedCategories.length === 0;
      },

      selectAllChecked() {
        if (this.allSelected) {
          return true;
        } else if (!this.noneSelected) {
          return null;
        } else {
          return false;
        }
      }
    },

    methods: {
      ...mapActions(useSelectionStore, [
        "selectSubpopulation",
        "removeSubpopulation"
      ]),

      isCategorySelected(c) {
        return this.subpopulation.isCategorySelected(c);
      },

      toggleSelection(category) {
        if (this.subpopulation.isCategorySelected(category)) {
          this.subpopulation.removeCategory(category);
        } else {
          this.subpopulation.addCategory(category);
        }
      },

      selectAllClick() {
        if (this.subpopulation.categories.length === this.subpopulation.selectedCategories.length) {
          this.subpopulation.clear();
        } else {
          this.subpopulation.clear();
          for (let cat of this.subpopulation.categories) {
            this.subpopulation.addCategory(cat);
          }
        }
      },

      finish() {
        let m = this.noneSelected ? this.removeSubpopulation : this.selectSubpopulation;

        m(this.subpopulation).then(() => {
          this.$router.push({name: 'select_subpopulation', query: {openGroupId: this.subpopulation.variable.variable_group_id}});
        });
      }
    },

    mounted() {
      let sp = this.selectedSubpopulations.find(s => s.variable.id === this.variableId);

      if (sp === undefined) {
        this.loadResource(
          api.getVariableWithCategories(this.variableId)
            .then(data => {
              this.subpopulation = new Subpopulation(data.variable, data.categories);
            })
        );
      } else {
        this.subpopulation = sp.dup();
      }
    },

    components: {
      AppCheckbox,
      AppLoading,
      AppStickyContainer,
      CategoryItem
    }
  }

</script>

<style lang="scss" scoped>

  .variable-label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  div.list .category {
    margin-top: 2px;
  }

</style>
