<template>
  <span :class="containerClass" aria-hidden="true">
    <app-svg class="svg" v-if="isSvg" v-bind="iconAttributes"></app-svg>
    <font-awesome-icon v-if="isFa" v-bind="iconAttributes"></font-awesome-icon>
  </span>
</template>

<script>

  import AppSvg from "App/components/AppSvg";
  import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
  
  const iconData = {
    collapse: { type: "svg", attributes: { icon: "collapse" } },
    expand: { type: "svg", attributes: { icon: "expand" } },
    filter: { type: "svg", attributes: { icon: "filter" } },
    flip: { type: "svg", attributes: { icon: "flip" } },
    share: { type: "svg", attributes: { icon: "share" } },
    drag: { type: "svg", attributes: { icon: "drag" } },
    availability: { type: "svg", attributes: { icon: "availability" } },
    codes: { type: "svg", attributes: { icon: "codes" } },
    comparability: { type: "svg", attributes: { icon: "comparability" } },
    description: { type: "svg", attributes: { icon: "description" } },
    editingProcedure: { type: "svg", attributes: { icon: "editingProcedure" } },
    flags: { type: "svg", attributes: { icon: "flags" } },
    questionnaire: { type: "svg", attributes: { icon: "questionnaire" } },
    universe: { type: "svg", attributes: { icon: "universe" } },
    titleExpand: { type: "svg", attributes: { icon: "titleExpand" } },
    titleCollapse: { type: "svg", attributes: { icon: "titleCollapse" } },


    angleDown: { type: "fa", attributes: { icon: "angle-down" } },
    checkbox: { type: "fa", attributes: { icon: "square" } },
    checkboxChecked: { type: "fa", attributes: { icon: "check-square" } },
    checkboxMixed: { type: "fa", attributes: { icon: "minus-square" } },
    chevronLeft: { type: "fa", attributes: { icon: "chevron-left" } },
    chevronRight: { type: "fa", attributes: { icon: "chevron-right" } },
    circle: { type: "fa", attributes: { icon: "circle" } },
    clipboard: { type: "fa", attributes: { icon: "clipboard" } },
    exclamation: { type: "fa", attributes: { icon: "exclamation-triangle" } },
    file: { type: "fa", attributes: { icon: "file" } },
    pencil: { type: "fa", attributes: { icon: "pencil-alt" } },
    search: { type: "fa", attributes: { icon: "search" } },
    spinner: { type: "fa", attributes: { icon: "spinner" } },
    times: { type: "fa", attributes: { icon: "times" } },
    toggleOn: { type: "fa", attributes: { icon: "toggle-on" } },
    toggleOff: { type: "fa", attributes: { icon: "toggle-on", flip: "horizontal" } },
  };

  const sizeData = {
    sm: { containerClass: 'is-small', faSize: 'sm' },
    md: { containerClass: '' },
    lg: { containerClass: 'is-medium', faSize: 'lg' },
    xl: { containerClass: 'is-medium', faSize: '2x' },
    "2xl": { containerClass: 'is-large', faSize: '3x' }
  };

  export default {
    props: {
      icon: {
        validator: (v) => iconData[v] !== undefined
      },
      size: {
        required: false,
        type: String,
        validator: (s) => sizeData[s] !== undefined,
        default: 'md'
      }
    },

    computed: {
      iconObject() {
        return iconData[this.icon];
      },

      sizeObject() {
        return sizeData[this.size];
      },

      isSvg() {
        return this.iconObject.type === "svg";
      },

      isFa() {
        return this.iconObject.type === "fa";
      },

      iconAttributes() {

        const attributes = Object.assign({}, this.iconObject.attributes);

        if (this.isFa) {
          if (this.sizeObject.faSize) {
            attributes.size = this.sizeObject.faSize;
          }
        }

        return attributes;
      },

      containerClass() {
        const classes = ["icon", this.sizeObject.containerClass];
        if (this.isSvg) {
          classes.push("is-svg");
        }
        return classes;
      }
    },

    components: {
      AppSvg,
      FontAwesomeIcon
    }
  }

</script>

<style lang="scss" scoped>

  .icon {
    &.is-svg {
      //width: auto;
    }
  }

  .svg {
    width: 100%;
    height: 100%;
  }

</style>