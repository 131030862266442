<template>
  <div>
    <h1 class="has-text-centered title is-5">Questionnaire Text</h1>

    <div v-if="availableSamples.length > 0">
      <app-popup
          :open="sampleSelectOpen"
          @dismiss="sampleSelectOpen = false"
          title="Select Sample">

        <div v-if="selectedSamples.length > 0" class="sample-option-title">
          Samples in Cart
        </div>

        <div class="sample-option" :class="{selected: samp.id === sampleId}" v-for="samp in selectedSamples" :key="samp.id" @click="selectSample(samp)">
          {{ samp.label }}
        </div>

        <div v-if="selectedSamples.length > 0" class="sample-option-title">
          Other Samples
        </div>

        <div class="sample-option" :class="{selected: samp.id === sampleId}" v-for="samp in availableSamples" :key="samp.id" @click="selectSample(samp)">
          {{ samp.label }}
        </div>

      </app-popup>

      <button class="button is-primary" @click="sampleSelectOpen = true">
        <span>{{ sample ? sample.label : '-- Select Sample --' }}</span>&nbsp;
        <app-icon icon="angleDown" size="sm"></app-icon>
      </button>
    </div>

    <div v-if="variableQuestionnaire !== null && !localLoading">

      <div v-if="variableQuestionnaire.snippets.length === 0">
        No Questionnaire Data
      </div>

      <div v-for="snippet in variableQuestionnaire.snippets" :key="snippet.source_variable_id + snippet.document_type">
        <div class="snippet-header">{{ snippet.document_type_label }}</div>
        <div class="snippet-links" v-if="snippet.pdf_link !== null || snippet.text_link !== null">
          View Entire Document:
          <a v-if="snippet.pdf_link !== null" target="_blank" :href="snippet.pdf_link">image</a>
          <a v-if="snippet.text_link !== null" target="_blank" :href="snippet.text_link">text</a>
        </div>
        <div class="content" v-external-links v-html="snippet.text"></div>
      </div>

    </div>

    <app-loading v-if="localLoading"></app-loading>

  </div>
</template>

<script>

  import { mapActions, mapState, mapWritableState } from "pinia";
  import { useMetadataStore } from "App/stores/metadata";
  import { useSelectionStore } from "App/stores/selection";

  import AppLoading from "App/components/AppLoading";
  import AppPopup from "App/components/AppPopup";

  export default {
    props: {
      variableId: {
        required: true,
        type: Number
      }
    },

    data() {
      return {
        sampleSelectOpen: false
      }
    },

    computed: {
      ...mapState(useMetadataStore, [
        "variable"
      ]),
      ...mapWritableState(useMetadataStore, [
        "sample",
        "variableQuestionnaire"
      ]),
      ...mapState(useSelectionStore, [
        "allSamples",
        "selectedSamples"
      ]),

      sampleId() {
        if (this.sample) {
          return this.sample.id;
        } else {
          return null;
        }
      },

      availableSamples() {
        if (this.variable !== null && this.allSamples !== null) {
          const lookup = new Set(this.variable.sample_ids);
          return this.allSamples.filter(s => lookup.has(s.id));
        } else {
          return [];
        }
      }
    },

    methods: {
      ...mapActions(useMetadataStore, [
        "getVariableQuestionnaire"
      ]),
      ...mapActions(useSelectionStore, [
        "getSamples"
      ]),

      selectSample(samp) {
        this.sample = samp;
        this.sampleSelectOpen = false;
      },

      updateData() {
        if (this.variableId !== null && this.sampleId !== null) {
          this.loadResource(
            this.getVariableQuestionnaire({ variableId: this.variableId, sampleId: this.sampleId})
          )
        } else {
          this.variableQuestionnaire = null;
        }
      }
    },

    created() {
      this.$watch(
        () => this.variableId + this.sampleId,
        () => this.updateData(),
        { immediate: true }
      );

      this.loadResource(
        this.getSamples()
      );
    },

    components: {
      AppLoading,
      AppPopup
    }
  }

</script>

<style lang="scss" scoped>
  @import "~App/styles/variables";

  .sample-option {

    cursor: pointer;
    background-color: $grey;
    padding: 0.25rem;
    margin-bottom: 0.25rem;

    &.selected {
      background-color: $green;
      color: $white;
    }
  }

  .snippet-header {
    color: $white;
    background-color: $black-ter;
    margin-top: 1rem;
  }

</style>