<template>

  <transition
      name="collapse"
      :css="false"
      appear
      @before-appear="before"
      @appear="enter"
      @after-appear="$emit('after-appear', $event)"
      @appear-cancelled="cancel"
      @before-enter="before"
      @enter="enter"
      @enter-cancelled="cancel"
      @leave="leave"
      @leave-cancelled="cancel"
  >
    <slot></slot>
  </transition>

</template>

<script>

  import TWEEN from '@tweenjs/tween.js';
  import { useGlobalTweenGroup } from "App/lib/useGlobalTweenGroup";
  const globalTweenGroup = useGlobalTweenGroup();


  export default {
    data() {
      return {
        currentAnimation: null
      }
    },

    methods: {
      cancel () {
        if (this.currentAnimation) {
          this.currentAnimation.stop();
          this.currentAnimation = null;
        }
      },
      before (targets) {
        targets.removeAttribute('style')
      },
      enter (targets, done) {
        const height = targets.scrollHeight;
        targets.style.overflow = 'hidden';

        this.currentAnimation = new TWEEN.Tween({height: 0}, globalTweenGroup)
          .to({height: height}, 250)
          .easing(TWEEN.Easing.Quadratic.In)
          .onUpdate(obj => targets.style.height = obj.height + "px")
          .onComplete(() => {
            this.heightAnimation = null;
            targets.removeAttribute('style');
            targets.style.opacity = 0.99;
            setTimeout(() => {
              // Fixes odd drawing bug in Chrome
              targets.style.opacity = 1.0;
            }, 1000);
            done();
          })
          .start();
      },
      leave (targets, done) {
        const height = targets.scrollHeight;
        targets.style.overflow = 'hidden';

        this.currentAnimation = new TWEEN.Tween({height: height}, globalTweenGroup)
          .to({height: 0}, 250)
          .easing(TWEEN.Easing.Quadratic.In)
          .onUpdate(obj => targets.style.height = obj.height + "px")
          .onComplete(() => {
            this.heightAnimation = null;
            targets.removeAttribute('style');
            done();
          })
          .start();
      }
    }
  }

</script>

<style lang="scss" scoped>



</style>