<template>
  <svg class="svg-icon" v-html="svgContent" v-bind="svgAttributes"></svg>
</template>

<script>

  import Collapse from "App/svg/collapse_v2";
  import Expand from "App/svg/expand_v2";
  import Filter from "App/svg/filter";
  import Flip from "App/svg/flip_v2";
  import Share from "App/svg/share_v2";

  import TitleCollapse from "App/svg/title-collapse";
  import TitleExpand from "App/svg/title-expand";

  import Drag from "App/svg/icon-drag";
  import Loader from "App/svg/loader";
  
  import Availability from "App/svg/icon-metadata-availability";
  import Codes from "App/svg/icon-metadata-codes";
  import Comparability from "App/svg/icon-metadata-comparability";
  import Description from "App/svg/icon-metadata-description";
  import EditingProcedure from "App/svg/icon-metadata-editing-procedure";
  import Flags from "App/svg/icon-metadata-flags";
  import Questionnaire from "App/svg/icon-metadata-questionnaire-text";
  import SourceVariables from "App/svg/icon-metadata-source-variables";
  import Universe from "App/svg/icon-metadata-universe";

  const nameMap = {
    collapse: Collapse,
    expand: Expand,
    filter: Filter,
    flip: Flip,
    share: Share,

    titleCollapse: TitleCollapse,
    titleExpand: TitleExpand,

    drag: Drag,
    loader: Loader,

    availability: Availability,
    codes: Codes,
    comparability: Comparability,
    description: Description,
    editingProcedure: EditingProcedure,
    flags: Flags,
    questionnaire: Questionnaire,
    sourceVariables: SourceVariables,
    universe: Universe
  };

  export default {
    props: {
      icon: {
        validator: (v) => nameMap[v] !== undefined
      }
    },

    computed: {
      svgObj() {
        return nameMap[this.icon];
      },
      svgAttributes() {
        const attrs = {};
        for (let a of ['viewBox', 'xmlns']) {
          if (this.svgObj.attributes[a]) {
            attrs[a] = this.svgObj.attributes[a];
          }
        }

        return attrs;
      },
      svgContent() {
        return this.svgObj.content;
      }
    }
  }

</script>

<style lang="scss">

  .svg-icon {
    path {
      fill: currentColor;
    }
  }

</style>
