import Vue from "vue";

function setATargets(el) {
  const anchors = el.getElementsByTagName("a");

  [...anchors]
    .filter(a => a.host !== window.location.host)
    .forEach(a => {
      a.target = "_blank";
      a.ref = "noopener";
    });
}

export function configureExternalLinks(app) {
  app.directive("external-links", {
    mounted(el) {
      setATargets(el);
    },

    updated(el) {
      setATargets(el);
    }
  })
}
