<template>
  <router-link :to="linkDestination" :[labelAttribute]="label">
    <slot></slot>
  </router-link>
</template>

<script>


  export default {
    props: {
      type: {
        type: String,
        required: true,
        validator: value => ["variable", "sample"].indexOf(value) !== -1
      },
      id: {
        type: [Number, String],
        required: true
      },
      returnRoute: {
        type: [Object, String],
        required: false,
        default: null
      },
      label: {
        type: String,
        required: false,
        default: null
      }
    },

    computed: {
      labelAttribute() {
        if (this.label === null) {
          return null;
        } else {
          return "aria-label"
        }
      },
      metadataRouteName() {
        if (this.type === "variable") {
          return "metadata_variable_description";
        } else {
          return "metadata_sample";
        }
      },
      serializedReturnRoute() {
        let rr = this.returnRoute;
        if (rr === null) {
          rr = {
            name: this.$route.name,
            query: this.$route.query
          };
        }

        if (typeof rr === "string") {
          rr = {name: rr}
        }

        return JSON.stringify(rr);
      },
      linkDestination() {
        return {
          name: this.metadataRouteName,
          params: {
            id: this.id
          },
          query: {
            returnRoute: this.serializedReturnRoute
          }
        }
      }
    }
  }

</script>
