<template>
  <section class="section">
    <div class="container has-text-centered has-text-white">
      <h1 class="title">
        IPUMS Abacus
      </h1>
      <div class="has-text-left">
        <p>
          IPUMS Abacus provides a mobile-friendly interface for quickly generating cross tabulations of IPUMS USA data. Select up to two variables from up to two time periods and cross-tabulated Census/ACS data will be at your fingertips.
        </p>
        <p>
          In other words, answer questions like "Which state had the biggest gender imbalance in 1920?" right from your phone! From powering your scholarly research to settling bar bets, IPUMS Abacus is with you wherever you go.
        </p>
      </div>
      <p class="go_btn">
        <router-link to="/tabulator" class="button is-primary">Begin</router-link>
      </p>
      <p>
        Questions? Feedback? <a href="mailto:ipums@umn.edu">ipums@umn.edu</a>
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'TheSplashScreen'
}
</script>

<style lang="scss" scoped>
  @import "~App/styles/variables";

  h1.title {
    color: $white;
  }

  .container a:not(.button) {
    color: $white;
    text-decoration: underline;
  }

  .container p {
    margin-bottom: 1rem;
  }

  p.go_btn {
    padding: 1rem;
  }

</style>
