<template>
  <div ref="container">
    <div ref="modal" :class="['popup', 'modal', { 'is-active': open }]">
      <div class="modal-background" @click="close"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <slot name="title">
            <p class="modal-card-title">{{ title }}</p>
            <span class="has-text-white" aria-label="close" @click="close">
              <app-icon icon="times" size="lg"></app-icon>
            </span>
          </slot>
        </header>

        <section class="modal-card-body">
          <slot></slot>
        </section>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    props: {
      open: {
        type: Boolean,
        default: false
      },
      title: String
    },

    mounted() {
      document.body.appendChild(this.$refs.modal);
    },

    beforeDestroy() {
      this.$refs.container.appendChild(this.$refs.modal);
    },

    methods: {
      close() {
        this.$emit("dismiss");
      }
    }
  }

</script>

<style lang="scss" scoped>

  @import "~App/styles/variables";

</style>