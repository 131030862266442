import { mapState, mapActions } from "pinia";
import { useApplicationConfigStore } from "../stores/applicationConfig";

export function configureMixins(app) {
  app.mixin({
    data() {
      return {
        localLoadingCount: 0
      };
    },

    computed: {
      ...mapState(useApplicationConfigStore, [
        "isLoading"
      ]),

      localLoading() {
        return this.localLoadingCount > 0;
      }
    },

    methods: {
      ...mapActions(useApplicationConfigStore, [
        'setError',
        'setLoading'
      ]),

      loadResource(promise) {
        this.setLoading(true);
        this.localLoadingCount = this.localLoadingCount + 1;

        return promise
          .catch(err => this.setError(err))
          .then(res => {
            this.setLoading(false);
            this.localLoadingCount = this.localLoadingCount - 1;
            return res;
          });
      }
    }
  });
}
