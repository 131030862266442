<template>

  <router-link class="button" :to="to">
    <span :style="spanStyle"></span><span><slot></slot></span>
  </router-link>

</template>

<script>

  export default {
    props: {
      to: {
        type: [String, Object],
        required: true
      },
      height: {
        type: String,
        required: false
      }
    },
    computed: {
      spanStyle() {
        if (this.height) {
          return { height: this.height };
        } else {
          return {};
        }
      }
    }
  }

</script>

<style lang="scss" scoped>
  .btn span {
    display: inline-block;
    vertical-align: middle;
  }
</style>
