<template>
  <div ref="app">
    <app-progress />
    <router-view v-if="applicationConfigStore.hasError !== true"></router-view>
    <the-error-page v-else></the-error-page>
    <app-alerts></app-alerts>

    <button v-if="false" id="makeError" style="position: absolute; right: 0; bottom: 0;" @click="setTimeout(() => { throw new Error('this is a manual error') })">Error!</button>
  </div>
</template>

<script>

  import { mapActions, mapStores } from 'pinia';
  import TheErrorPage from 'App/components/TheErrorPage.vue';
  import AppAlerts from "App/components/AppAlerts";
  import AppProgress from "App/components/AppProgress";
  // import { vueTopprogress } from 'vue-top-progress';
  import api from 'App/lib/Api';
  import BrowserFullscreen from 'App/lib/BrowserFullscreen';
  import { useApplicationConfigStore } from 'App/stores/applicationConfig';
  import { useTabulationStore } from 'App/stores/tabulation';
  import { useGlobalTweenGroup } from "App/lib/useGlobalTweenGroup";

  const globalTweenGroup = useGlobalTweenGroup();

  export default {
    name: 'app',

    data() {
      return {
        scrollData: {
          allowUp: false,
          allowDown: false,
          slideBeginY: 0
        }
      }
    },

    computed: {
      ...mapStores(useApplicationConfigStore)
    },

    mounted() {

      // Setup global animation loop
      function animate () {
        globalTweenGroup.update();
        requestAnimationFrame(animate);
      }
      animate();

      this.$watch("applicationConfigStore.hasError", function(newVal, oldVal) {
        if (newVal) {
          this.reportError(this.applicationConfigStore.errorObj);
        }
      });

      let component = this;

      window.onerror = function(message, source, lineno, colno, error) {
        if (!error) {
          error = {
            name: 'Error',
            message: message,
            fileName: source,
            lineNumber: lineno,
            columnNumber: colno
          };
        }

        // Ignore these errors
        if (message.toString().match(/Failed to read the 'cssRules' property from 'CSSStyleSheet': Cannot access rules/i)) {
          return;
        }

        component.setError(error);
      };

      window.addEventListener("keydown", function(event) {
        component.appKey(event);
      });

      // Disable iOS "bouncy" scroll behavior
      // (found to be buggy; disabled.
      // if (Config.isIOS) {
      //   document.documentElement.addEventListener('touchstart', function(event) {
      //     console.log(this);
      //     console.log(this.scrollTop);
      //     console.log(this.scrollHeight);
      //     console.log(this.clientHeight);
      //     component.scrollData.allowUp = (this.scrollTop > 0);
      //     component.scrollData.allowDown = (this.scrollTop < this.scrollHeight - this.clientHeight);
      //     component.scrollData.slideBeginY = event.pageY;
      //     console.log(component.scrollData);
      //   });
      //
      //   document.documentElement.addEventListener('touchmove', function(event) {
      //     const up = (event.pageY > component.scrollData.slideBeginY);
      //     const down = (event.pageY < component.scrollData.slideBeginY);
      //     component.scrollData.slideBeginY = event.pageY;
      //     if ((up && component.scrollData.allowUp) || (down && component.scrollData.allowDown)) {
      //       event.stopPropagation();
      //     }
      //     else {
      //       event.preventDefault();
      //     }
      //   });
      // }
    },

    methods: {
      ...mapActions(useTabulationStore, ["resetCrossTabData"]),

      reportError(err) {
        if (!err) {
          return;
        }

        try {
          api.postReportError({
            name: err.name || "Error",
            message: (err.message) ? err.message : err.toString(),
            stack: (err.stack && err.stack.split) ? err.stack.split("\n").filter(l => l.length > 0) : null,
            fileName: err.fileName || null,
            lineNumber: err.lineNumber || null,
            columnNumber: err.columnNumber || null
          })
        } catch(err) {
          // noop
        }
      },

      appKey(evt) {
        if (evt) {
          let target = evt.target;
          let key = evt.key;
          let isAlt = evt.altKey;
          let targetTag = target ? target.tagName.toUpperCase() : null;
          if (targetTag !== "TEXTAREA" && targetTag !== "INPUT") {
            if (key === "f" || key === "F") {
              //BrowserFullscreen.toggleFullscreen();
            } else if (isAlt && key === "r" || key === "R") {
              this.resetCrossTabData();
            }
          }
        }
      }
    },

    components: {
      AppAlerts,
      AppProgress,
      'the-error-page': TheErrorPage
    }
  }

</script>

<style lang="scss">
  @import "~App/styles/variables";
</style>
