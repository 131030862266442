<template>
  <div>

    <app-sticky-container>
      <div class="drawer_wrapper">
        <app-drawer>
          <cross-tab-cart></cross-tab-cart>
          <cross-tab-menu></cross-tab-menu>
        </app-drawer>
      </div>
    </app-sticky-container>

    <div class="crosstab-data">
      <cross-tab></cross-tab>
    </div>

  </div>
</template>

<script>

  import AppDrawer from "App/components/AppDrawer";
  import AppStickyContainer from "App/components/AppStickyContainer";
  import CrossTab from 'App/components/CrossTab';
  import CrossTabCart from 'App/components/CrossTabCart';
  import CrossTabMenu from 'App/components/CrossTabMenu';

  export default {
    computed: {
    },
    methods: {
    },
    components: {
      AppDrawer,
      AppStickyContainer,
      CrossTab,
      CrossTabCart,
      CrossTabMenu
    }
  }

</script>

<style lang="scss" scoped>
  @import "~App/styles/variables";

  .crosstab-data {
    margin-top: 0.5rem;
  }

</style>
