import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import api from "App/lib/Api";

export const useMetadataStore = defineStore('metadata', () => {
  const sample = ref(null);
  const variable = ref(null);
  const variableCodes = ref(null);
  const variableComparability = ref(null);
  const variableUniverse = ref(null);
  const variableQuestionnaire = ref(null);
  const variableFlags = ref(null);
  const variableEditing = ref(null);

  async function getVariable(variableIdOrMnemonic) {
    if (variable.value === null || (variable.value.id !== parseInt(variableIdOrMnemonic) && variable.value.mnemonic !== variableIdOrMnemonic)) {
      variable.value = null;
      variable.value = await api.variable(variableIdOrMnemonic);
    }

    return variable.value;
  }

  async function getVariableCodes(variableId) {
    if (variableCodes.value === null || variableCodes.value.variable_id !== variableId) {
      variableCodes.value = await api.variableCodes(variableId);
    }

    return variableCodes.value;
  }

  async function getVariableComparability(variableId) {
    if (variableComparability.value === null || variableComparability.value.variable_id !== variableId) {
      variableComparability.value = await api.variableComparability(variableId);
    }

    return variableComparability.value;
  }

  async function getVariableUniverse(variableId) {
    if (variableUniverse.value === null || variableUniverse.value.variable_id !== variableId) {
      variableUniverse.value = await api.variableUniverse(variableId);
    }

    return variableUniverse.value;
  }

  async function getVariableFlags(variableId) {
    if (variableFlags.value === null || variableFlags.value.variable_id !== variableId) {
      variableFlags.value = await api.variableFlags(variableId);
    }

    return variableFlags.value;
  }

  async function getVariableEditing(variableId) {
    if (variableEditing.value === null || variableEditing.value.variable_id !== variableId) {
      variableEditing.value = await api.variableEditing(variableId);
    }

    return variableEditing.value;
  }

  async function getVariableQuestionnaire({variableId, sampleId}) {
    if (variableQuestionnaire.value === null || variableQuestionnaire.value.variable_id !== variableId || variableQuestionnaire.value.sample_id !== sampleId) {
      variableQuestionnaire.value = await api.variableQuestionnaire(variableId, sampleId);
    }

    return variableQuestionnaire.value;
  }

  async function getSample(sampleId) {
    if (sample.value === null || sample.value.id !== sampleId) {
      sample.value = await api.sample(sampleId);
    }

    return sample.value;
  }

  return {
    sample,
    variable,
    variableCodes,
    variableComparability,
    variableUniverse,
    variableQuestionnaire,
    variableFlags,
    variableEditing,

    getSample,
    getVariable,
    getVariableCodes,
    getVariableComparability,
    getVariableEditing,
    getVariableFlags,
    getVariableQuestionnaire,
    getVariableUniverse
  }
});
