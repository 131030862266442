<template>
  <div class="metadata">
    <app-sticky-container>
      <app-return-header :to="backLocation">
        <div class="variable-title" v-if="variable !== null">{{ variable.mnemonic }} - {{ variable.label }}</div>
        <app-loading v-else></app-loading>
      </app-return-header>
      <app-drawer>
        <div v-if="variableId !== null" class="columns is-multiline is-gapless is-mobile">
          <div v-for="nav in navLinks" :key="nav.link" class="column is-one-quarter-touch">
            <router-link class="nav-link has-text-centered" :to="{name: 'metadata_variable_' + nav.link, params: {id: (variable ? variable.mnemonic : variableId)}, query: navQuery}">
              <app-icon :icon="nav.icon" size="xl"></app-icon><br/>
              {{nav.label}}
            </router-link>
          </div>
        </div>
        <app-loading v-else></app-loading>
      </app-drawer>
    </app-sticky-container>

    <div class="var_metadata" v-if="variableId !== null">
      <router-view :variable-id="variableId"></router-view>
    </div>
    <app-loading v-else></app-loading>
  </div>
</template>

<script>

  import { mapActions, mapState } from "pinia";
  import { useMetadataStore } from "App/stores/metadata";

  import AppDrawer from "App/components/AppDrawer";
  import AppIcon from "App/components/AppIcon";
  import AppLoading from "App/components/AppLoading";
  import AppReturnHeader from "App/components/AppReturnHeader";
  import AppStickyContainer from "App/components/AppStickyContainer";

  export default {
    computed: {
      ...mapState(useMetadataStore, {
        variable: store => store.variable
      }),

      returnRoute() {
        return this.$route.query.returnRoute;
      },

      variableQueryParam() {
        return this.$route.params.id;
      },

      variableId() {
        if (this.variable !== null) {
          return this.variable.id;
        } else if (/^\d+$/.test(this.variableQueryParam.toString())) {
          return parseInt(this.variableQueryParam);
        } else {
          return null;
        }
      },

      backLocation() {
        if (this.returnRoute) {
          return JSON.parse(this.returnRoute);
        } else {
          return { name: 'tabulator' }
        }
      },

      navLinks() {
        return [
          {label: 'Description', icon: 'description', link: 'description'},
          {label: 'Codes', icon: 'codes', link: 'codes'},
          {label: 'Comp.', icon: 'comparability', link: 'comparability'},
          {label: 'Universe', icon: 'universe', link: 'universe'},
          {label: 'Quest. text', icon: 'questionnaire', link: 'questionnaire_text'},
          {label: 'Flags', icon: 'flags', link: 'flags'},
          {label: 'Availability', icon: 'availability', link: 'availability'},
          {label: 'Editing', icon: 'editingProcedure', link: 'editing'}
        ];
      },

      navQuery() {
        if (this.returnRoute) {
          return { returnRoute: this.returnRoute };
        } else {
          return null;
        }
      }
    },

    methods: {
      ...mapActions(useMetadataStore, [
        "getVariable"
      ]),

      loadVariable() {
        this.loadResource(
          this.getVariable(this.variableQueryParam)
            .then(v => {
              if (v.mnemonic !== this.variableQueryParam) {
                this.$router.replace({
                  name: this.$route.name,
                  params: {id: v.mnemonic},
                  query: this.navQuery
                })
              }
            })
        );
      }
    },

    created() {
      this.$watch('variableQueryParam', function() {
          this.loadVariable();
      },
      {
        immediate: true
      });
    },

    components: {
      AppDrawer,
      AppIcon,
      AppLoading,
      AppReturnHeader,
      AppStickyContainer
    }
  }

</script>

<style lang="scss" scoped>
  @import "~App/styles/variables";

  .variable-title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .metadata {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .nav-link {
    display: block;
    color: black;

    &.router-link-active {
      color: $green;
    }
  }

  .var_metadata {
    background-color: $grey;
    flex-grow: 1;
    padding: 1rem;
  }
</style>
