<template>
  <div>
    <h1 class="has-text-centered title is-5">Universe</h1>

    <div v-if="!localLoading" class="content">
      <ul >
        <li v-for="u in variableUniverse.universes" :key="u">
          <app-tabular-text :value="u"></app-tabular-text>
        </li>
      </ul>
    </div>
    <app-loading v-else></app-loading>
  </div>
</template>

<script>

  import { mapActions, mapState } from "pinia";
  import { useMetadataStore } from "App/stores/metadata";

  import AppLoading from "App/components/AppLoading";
  import AppTabularText from "App/components/AppTabularText";

  export default {
    props: {
      variableId: {
        required: true,
        type: Number
      }
    },

    computed: {
      ...mapState(useMetadataStore, [
        "variableUniverse"
      ])
    },

    methods: {
      ...mapActions(useMetadataStore, [
        "getVariableUniverse"
      ])
    },

    watch: {
      variableId: {
        handler: function() {
          this.loadResource(
            this.getVariableUniverse(this.variableId)
          );
        },
        immediate: true
      }
    },

    components: {
      AppLoading,
      AppTabularText
    }
  }

</script>
