<template>

  <span :class="spanClasses">
    <app-icon icon="chevronRight"></app-icon>
  </span>

</template>

<script>

  export default {
    props: {
      direction: {
        required: false,
        type: String
      },
    },
    computed: {
      spanClasses() {
        return [
          'app-arrow',
          {
            left: this.direction == "left",
            up: this.direction == "up",
            down: this.direction == "down"
          }
        ];
      }
    }
  }

</script>

<style lang="scss" scoped>

  .app-arrow {
    transition: transform .25s ease;

    &.left {
      transform: rotate(180deg);
    }

    &.down {
      transform: rotate(90deg);
    }

    &.up {
      transform: rotate(-90deg);
    }
  }

</style>
