import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import api from "App/lib/Api";
import { useSelectionStore } from "./selection";

export const useSearchVariablesStore = defineStore('searchVariables', () => {
  const variableGroups = ref([]);
  const hasVariableGroups = ref(false);
  const variableCache = ref({});
  const availabilityState = ref(null);

  function variablesForGroup(variableGroupId) {
    if (variableCache.value) {
      return variableCache.value[variableGroupId] || null;
    } else {
      return null;
    }
  }

  async function getVariableGroups() {
    const selectionStore = useSelectionStore();
    const newAvailabilityState = selectionStore.selectedSamples.map(s => s.id).join("||");
    if (!hasVariableGroups.value || (newAvailabilityState !== availabilityState.value)) {
      variableGroups.value = await api.getVariableGroups(selectionStore.selectedSamples.map(s => s.id));
      hasVariableGroups.value = true;
      variableCache.value = {};
      availabilityState.value = newAvailabilityState;
    }

    return variableGroups.value;
  }

  async function getVariablesForGroup(variableGroupId) {
    if (variableCache.value === null) {
      variableCache.value = {};
    }

    if (!variableCache.value[variableGroupId]) {
      variableCache.value[variableGroupId] = await api.getVariablesByGroup(variableGroupId);
    }

    return variableCache.value[variableGroupId];
  }

  return {
    availabilityState,
    variableCache,
    variableGroups,
    hasVariableGroups,

    getVariableGroups,
    getVariablesForGroup,
    variablesForGroup
  }
});
