<template>
  <div class="sticky">
    <div class="stickySpacker" :style="spacerStyle"></div>
    <div class="stickyWrapper" ref="wrapper" :style="wrapperStyle">
      <slot></slot>
    </div>
  </div>
</template>

<script setup>

  import { computed, nextTick, ref, useTemplateRef } from "vue";
  import { useEventListener, useResizeObserver } from "@vueuse/core";


  const props = defineProps({
    backgroundColor: {
      required: false,
      type: String,
      default: "white"
    }
  });

  const operationQueued = ref(false);
  const sticky = ref(false);
  const originalOffset = ref(0);
  const originalWidth = ref(0);
  const originalHeight = ref(0);
  const wrapperElement = useTemplateRef("wrapper");

  const appElement = computed(() => document.getElementById("app"));

  const spacerStyle = computed(() => {
    return {
      height: originalHeight.value + "px",
      width: originalWidth.value + "px"
    };
  });

  const wrapperStyle = computed(() => {
    const style = {
      backgroundColor: props.backgroundColor,
      position: "relative"
    };

    if (sticky.value) {
      Object.assign(style, {
        width: originalWidth.value + "px",
        position: "fixed",
        top: 0,
        zIndex: 20
      });
    }

    return style;
  });

  useResizeObserver(wrapperElement, (entries) => {
    queueStyleUpdate(false);
  });

  useEventListener(window, "scroll", () => {
    queueStyleUpdate(false);
  });

  useEventListener(window, "resize", () => {
    queueStyleUpdate(true);
  });

  function queueStyleUpdate(resetWidth) {
    if (wrapperElement.value && !operationQueued.value) {
      window.requestAnimationFrame(() => {
        checkScrollPosition(resetWidth);
        operationQueued.value = false;
      });

      operationQueued.value = true;
    }
  }

  function checkScrollPosition(resetWidth) {

    const setSticky = () => {
      const wrapperRect = wrapperElement.value.getBoundingClientRect();
      const scrollOffset = window.scrollY;
      const yDelta = wrapperRect.height - originalHeight.value;
      const yShinkDelta = yDelta < 0 ? yDelta : 0;

      if (sticky.value === false && wrapperRect.top <= 0) {
        originalOffset.value = wrapperRect.top + scrollOffset;
        originalWidth.value = wrapperRect.width;
        originalHeight.value = wrapperRect.height;
        sticky.value = true;
      } else if (sticky.value === true && scrollOffset < (originalOffset.value - yShinkDelta)) {
        if (yShinkDelta > 0) {
          appElement.value.scrollTop = scrollOffset + yShinkDelta;
        }
        originalWidth.value = 0;
        originalHeight.value = 0;
        sticky.value = false;

      }
    };


    if (resetWidth) {
      originalWidth.value = 0;
      originalHeight.value = 0;
      sticky.value = false;
      nextTick(() => {
        setSticky();
      });
    } else {
      setSticky();
    }
  }

</script>

<style lang="scss" scoped>

</style>