<template>
  <div class="metadata">
    <app-return-header :to="backLocation">
      <span v-if="sample !== null">{{ sample.label }}</span>
      <app-loading v-else></app-loading>
    </app-return-header>

    <div v-if="sample !== null" class="samp_metadata">
      <h3><a :href="sampleMetadataUrl">{{ sample.description }}</a></h3>

      <p v-if="sample.note_rich"><app-icon icon="exclamation"></app-icon> <span v-html="sample.note_rich"></span></p>
      <p v-else-if="sample.note"><app-icon icon="exclamation"></app-icon> {{ sample.note }} </p>
    </div>
    <app-loading v-if="localLoading"></app-loading>
  </div>
</template>

<script>

  import { mapActions, mapState } from "pinia";
  import { useApplicationConfigStore } from "../stores/applicationConfig";
  import { useMetadataStore } from "App/stores/metadata";

  import AppIcon from "App/components/AppIcon";
  import AppLoading from "App/components/AppLoading";
  import AppReturnHeader from "App/components/AppReturnHeader";

  export default {
    computed: {
      ...mapState(useApplicationConfigStore, ['baseIpumsUrl']),
      ...mapState(useMetadataStore, {
        sample: store => store.sample
      }),

      returnRoute() {
        return this.$route.query.returnRoute;
      },

      sampleId() {
        return this.$route.params.id;
      },

      backLocation() {
        if (this.returnRoute) {
          return JSON.parse(this.returnRoute);
        } else {
          return { name: 'tabulator' };
        }
      },

      navQuery() {
        if (this.returnRoute) {
          return { returnRoute: this.returnRoute };
        } else {
          return null;
        }
      },

      sampleMetadataUrl() {
        if (this.sample !== null) {
          return this.baseIpumsUrl + "/usa/sampdesc.shtml#" + this.sample.name;
        }
      }
    },

    methods: {
      ...mapActions(useMetadataStore, [
        "getSample"
      ])
    },

    created() {

      this.loadResource(
        this.getSample(this.sampleId)
      );
    },

    components: {
      AppIcon,
      AppLoading,
      AppReturnHeader
    }
  }

</script>

<style lang="scss" scoped>
  @import "~App/styles/variables";

  .metadata {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .nav-link {
    display: block;
    color: black;

    &.router-link-active {
      color: $green;
    }
  }

  .samp_metadata {
    background-color: $grey;
    flex-grow: 1;
    padding: 1rem;
  }
</style>
