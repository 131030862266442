<template>
  <div class="icon-button" :class="{ disabled: disabled }" @click.prevent.stop="handleClick">
    <app-icon :icon="icon" :size="size"></app-icon>
  </div>
</template>

<script>

  export default {
    emits: ['click'],

    props: {
      icon: {
        type: String,
        required: true
      },

      size: {
        type: String,
        required: false
      },

      disabled: {
        type: Boolean,
        required: false,
        default: false
      }
    },

    methods: {
      handleClick() {
        if (!this.disabled) {
          this.$emit("click");
        }
      }
    }
  }

</script>

<style lang="scss" scoped>

  @import "~App/styles/variables";

  div.icon-button {
    cursor: pointer;

    &:hover:not(.disabled) {
      background-color: darken($white, 2.5%);
    }

    &.disabled {
      cursor: default;
      opacity: 0.5;
    }
  }

</style>