<template>
  <span class="tnum" v-html="textMarkup"></span>
</template>

<script>

  export default {
    props: {
      value: {
        required: true,
        type: String
      }
    },

    computed: {
      textMarkup() {
        // The tnum font feature doesn't seem to work if there are non-numeric characters in an element.
        if (this.value) {
          return this.value.replace(/([\d.]+)/g, "<span>$1</span>");
        } else {
          return "";
        }
      }
    }
  }

</script>