<template>
  <div :class="{ checked: isSelected }" class="list-item" @click.prevent="toggleSelect">
    <app-checkbox @toggle="toggleSelect" class="list-checkbox" :value="isSelected"></app-checkbox>
    <span class="list-label">
      {{ showGeneralLabel ? category.general_label : category.label}}
    </span>
  </div>
</template>

<script>

  import AppCheckbox from "App/components/AppCheckbox";

  export default {
    props: {
      category: {
        required: true,
        type: Object
      },

      isSelected: {
        required: true,
        type: Boolean
      },

      showGeneralLabel: {
        required: false,
        type: Boolean,
        default: false
      }
    },

    methods: {
      toggleSelect() {
        this.$emit("toggleSelection", this.category);
      }
    },

    components: {
      AppCheckbox
    }
  }

</script>

<style lang="scss" scoped>

  .list-checkbox {
    margin-left: 1.25em;
  }

  span.spacer {
    display: inline-block;
    width: 1.25em;
  }

</style>