<template>
  <div class="alert-container">
    <app-alert-item v-for="alert in visibleAlerts" :key="alert.id" :alert="alert"></app-alert-item>
  </div>
</template>

<script>

  import AppAlertItem from "App/components/AppAlertItem";
  import { mapState } from "pinia";
  import { useApplicationConfigStore } from "App/stores/applicationConfig";

  export default {
    computed: {
      ...mapState(useApplicationConfigStore, [
        'visibleAlerts'
      ])
    },

    components: {
      AppAlertItem
    }
  }

</script>

<style lang="scss" scoped>

  div.alert-container {
    position: fixed;
    margin: 0 1.5rem;
    bottom: 0;
    left: 0;
    right: 0;
  }

</style>