<template>
  <div>
    <collapse-transition name="collapse">
      <div v-if="shouldDisplay">

        <div ref="groupHeader" class="variable-group is-clearfix" :class="headerClasses" @click.prevent="headerClick">
          <span class="variable-name">{{ variableGroup.name }} ({{ variableCount }} Variables) <app-icon class="selected-icon" icon="circle"></app-icon> </span>
          <app-arrow-button class="variable-arrow" :direction="isOpen ? 'down' : 'right'"></app-arrow-button>
        </div>

        <app-loading v-if="localLoading"></app-loading>
        <collapse-transition @after-appear="scrollMe">
          <div v-if="isOpen && variables.length > 0">
            <fade-transition-group>
              <component :is="variableItemComponent"
                         class="variable"
                         v-for="v in variablesList"
                         :key="v.id"
                         :variable="v"
                         @variableClick="variableClick">
              </component>
            </fade-transition-group>
          </div>
        </collapse-transition>
      </div>
    </collapse-transition>
  </div>
</template>

<script>

  import AppLoading from 'App/components/AppLoading';
  import ScrollIntoView from 'scroll-into-view';
  import CollapseTransition from 'App/components/CollapseTransition';
  import FadeTransitionGroup from 'App/components/FadeTransitionGroup';
  import AppArrowButton from 'App/components/AppArrowButton';
  import { mapActions, mapState } from "pinia";
  import { useSelectionStore} from "App/stores/selection";
  import { useSearchVariablesStore } from "App/stores/searchVariables";

  export default {
    data() {
      return {
      };
    },

    props: {
      variableGroup: {
        type: Object,
        required: true
      },
      showUnavailable: {
        type: Boolean,
        required: true
      },
      openGroupId: {
        type: Number
      },
      selectedVariables: {
        required: true,
        type: Array
      },
      variableItemComponent: {
        required: true,
        type: Object
      },
    },

    computed: {
      variables() {
        return this.variablesForGroup(this.variableGroup.id) || [];
      },

      variablesList() {
        if (this.showUnavailable) {
          return this.variables;
        } else {
          return this.variables.filter(v => this.isVariableAvailable(v));
        }
      },
      isAvailable() {
        return this.variableGroup.availableVariableCount > 0;
      },
      hasAnyVariables() {
        return this.variableGroup.variableCount > 0;
      },
      shouldDisplay() {
        return this.hasAnyVariables && (this.showUnavailable || this.isAvailable);
      },
      isOpen() {
        return this.openGroupId == this.variableGroup.id;
      },
      isSelected() {
        return this.selectedVariables.findIndex(v => v.variable_group_id === this.variableGroup.id) >= 0;
      },
      variableCount() {
        if (this.showUnavailable) {
          return this.variableGroup.variableCount;
        } else {
          return this.variableGroup.availableVariableCount;
        }
      },
      headerClasses() {
        return {
          unavailable: !this.isAvailable,
          selected: this.isSelected,
          opened: this.isOpen,
        }
      }
    },

    methods: {
      ...mapActions(useSearchVariablesStore, [
          "getVariablesForGroup",
          "variablesForGroup"
      ]),
      ...mapActions(useSelectionStore, [
          "isVariableAvailable"
      ]),

      headerClick () {
        this.$emit("headerClick", this.variableGroup);
      },
      variableClick(variable) {
        this.$emit("variableClick", variable);
      },
      scrollMe() {
        ScrollIntoView(this.$refs.groupHeader);
      }
    },

    watch: {
      isOpen: {
        handler(newValue) {
          if (newValue === true) {
            this.loadResource(
              this.getVariablesForGroup(this.variableGroup.id)
            );
          }
        },
        immediate: true
      }
    },

    components: {
      AppLoading,
      CollapseTransition,
      AppArrowButton,
      FadeTransitionGroup
    }
  }

</script>

<style lang="scss" scoped>

  @import "~App/styles/variables";

  .variable-group {
    position: relative;
    margin-bottom: 0.16em;
    padding: 0.5em 0 0.2em 0.5em;
    background-color: $cyan-lighter;
    cursor: pointer;
    display: flex;

    .selected-icon {
      color: $green;
      display: none;
    }

    &.opened {
      background-color: $grey-darker;
      color: $white;
    }

    &.unavailable {
      a {
        font-style: italic;
      }
    }

    &.selected {
      .selected-icon {
        display: inline;
      }
    }
  }

  .variable-name {
    flex-grow: 1;
    min-width: 0;
  }

  .variable-arrow {
    width: 1.5rem;
    display: block;
  }

</style>
