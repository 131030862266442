<template>
  <div v-show="visible">
    <div class="progress-bar" :class="{ 'failed': this.failed }" :style="{ width: progress + '%' }" />
  </div>
</template>

<script>
  import TWEEN from '@tweenjs/tween.js';
  import {mapState, mapStores} from "pinia";
  import { useGlobalTweenGroup } from "App/lib/useGlobalTweenGroup";
  import { useApplicationConfigStore } from "App/stores/applicationConfig";
  let progressInterval;

  const globalTweenGroup = useGlobalTweenGroup();
  // In milliseconds
  const BAR_LOAD_DURATION = 600;

  export default {
    name: 'ProgressBar',
    data() {
      return {
        visible: false,
        failed: false,
        progress: 0,
        progressBarAnimation: null
      };
    },

    computed: {
      ...mapState(useApplicationConfigStore, [
          'hasError'
      ])
    },

    mounted() {
      this.$watch("isLoading", function(newVal, oldVal) {
        if (newVal) {
          this.run();
        } else {
          this.stop();
        }
      });

      this.$watch("hasError", function(newVal, oldVal) {
        if (newVal) {
          this.fail();
        }
      });
    },

    methods: {
      show() {  // this will show the progress bar but let something external update the progress
        this.stop()
        this.visible = true;
      },
      run() { // this will start a cycling progress bar for a presumably indeterminate loading cycle
        this.stop();
        this.visible = true;
        this.progress = 0;
        this.progressBarAnimation = new TWEEN.Tween(this, globalTweenGroup)
            .to({progress: 100}, BAR_LOAD_DURATION)
            .easing(TWEEN.Easing.Quadratic.InOut)
            .repeat(Infinity)
            .onComplete(() => { this.progress = 0; this.stop() }) // I don't think this will ever be triggered
            .start();
      },
      fail() { // this will put a solid red bar across the top.
        this.stop();
        this.progress = 100;
        this.failed = true;
        this.visible = true;
      },
      stop() {
        if (this.progressBarAnimation !== null) {
          this.progressBarAnimation.stop();
          this.progressBarAnimation = null;
        }
        this.visible = false;
      }
    }
  }
</script>

<style lang="scss" scoped>
  @use "~App/styles/variables" as v;

  .progress-bar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    height: 3px;
    background-color: v.$turquoise;
    border-radius: 5px;
    overflow: hidden;

    &.failed {
      background-color: v.$red;
    }
  }
</style>
